import { GatsbyImage, getImage } from 'gatsby-plugin-image/';
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .gatsby-image-wrapper {
    margin-bottom: 0.8rem;
    img {
      height: 72px;
    }
  }
`;

const itemVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
      delay: 0.2,
    },
  },
};

const MotionCard = motion(StyledCard);

export default function IconCard({ data }) {
  return (
    <MotionCard
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, margin: '300px' }}
      variants={itemVariants}
    >
      <GatsbyImage
        image={getImage(data.icon.asset.gatsbyImageData)}
        alt={data.name}
      />
      <p>{data.name}</p>
    </MotionCard>
  );
}
